@import "src/scss/variables";

@mixin badge ($padding: 4px 12px) {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
  padding: $padding;
  border-radius: 34px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: $brand-secondary-subtle;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: $brand-secondary-strong;
}

@mixin h2title ($font-size: 2.25rem, $margin: 24px 0 12px) {
  font-size: $font-size;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
  color: $text_icons-Primary;
  margin: $margin;
}

@mixin subtitle($font-size: 1.25rem, $text-align: center, $line-height: 30px) {
  color: $text_icons-Secondary;
  text-align: $text-align;
  font-size: $font-size;
  font-weight: 400;
  line-height: $line-height;
}

@mixin BadgeYellow {
  color: $brand-primary-badge;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 20px;
}

@mixin h3title($font-size: 1.5rem) {
  color: $text_icons-Primary;
  font-size: $font-size;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

@mixin h3titleMini($font-size: 1.25rem) {
  color: $text_icons-Primary;
  font-size: $font-size;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
}

@mixin description($font-size: 1rem, $line-height: 140%) {
  color: $text_icons-Secondary;
  font-size: $font-size;
  font-weight: 400;
  line-height: 140%;
}

@mixin gridBlock {
  border-radius: 16px;
  background: $bg-secondary;
}

@mixin calcTitle {
  color: #FFF;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 30px;
}
/* Text & Icons */

$text_icons-Primary: rgba(255,255,255,0.9);
$text_icons-Secondary: rgba(255,255,255,0.5);
$text_icons-Borders: rgba(255,255,255,0.2);
$text_icons-onPrimary: rgba(0,0,0, 1);

/* Background */

$bg-primary: rgba(18, 18, 18, 1);
$bg-secondary: rgba(33, 33, 33, 1);
$bg-evenHigher: rgba(255, 255, 255, 0.08);
$bg-secondary-blured: rgba(33, 33, 33, 0.8);
$bg-darkener: rgba(0, 0, 0, 0.7);
$bg-primary-blured: rgba(18, 18, 18, 0.8);

/* Brand */

$brand-primary-strong: rgba(255, 241, 85, 1);
$brand-primary-subtle: rgba(255, 241, 85, 0.2);
$brand-primary-badge: rgba(255, 241, 85, 0.5);
$brand-secondary-strong: rgba(89, 184, 150, 1);
$brand-secondary-subtle: rgba(89, 184, 150, 0.2);

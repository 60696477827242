@import "src/scss/variables";

.inpageNav_wrapper {
  position: fixed;
  bottom: 16px;
  width: fit-content;
  z-index: 99;

  .bgBlur {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(40px);
    z-index: -2;
    border-radius: 16px;
  }
}

.inpageNav_description {
  display: none;
}
.inpageNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  gap: 4px;
  border-radius: 16px;
  border: 2px solid $text_icons-Borders;
  background: $bg-secondary-blured;


  .item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 8px;
    padding-right: 12px;
    color: $text_icons-Secondary;
    text-decoration: none;
    height: 44px;
    font-weight: 500;
    font-size: 0.875rem;

    //&:hover {
    //  border-radius: 8px;
    //  background: $bg-evenHigher;
    //  color: $text_icons-Primary;
    //
    //  svg path {
    //    fill: $text_icons-Primary;
    //    fill-opacity: 1;
    //  }
    //}

    &:not(.active):hover {
      border-radius: 8px;
      background: $bg-evenHigher;
      color: $text_icons-Primary;

      svg path {
        fill: $text_icons-Primary;
        fill-opacity: 1;
      }
    }

    &.active {
      border-radius: 8px;
      background: $brand-primary-subtle;
      color: $brand-primary-strong;
      transition: all ease-in-out 50ms;

      svg path {
        fill: $brand-primary-strong;
        fill-opacity: 1;
      }
    }
  }

  svg path {
    fill: #FFFFFF;
    fill-opacity: 0.5;
  }

  hr {
    width: 2px;
    height: 22px;
    border: 1px solid $text_icons-Borders;
    border-radius: 2px;
    margin: 0 12px;
  }
}

@media screen and (max-width: 991px) {
    .inpageNav_wrapper {
      bottom: 0;
      width: 100%;
      left: 0;
      white-space: nowrap;

      .bgBlur {
        border-radius: 0px;
      }

      .inpageNav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 16px 0 8px 16px;
        gap: 4px;
        border-radius: 0;
        border: none;
        background: $bg-secondary-blured;
        margin: 0 auto;
        overflow-x: auto;
      }


    }

  .inpageNav_description {
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 16px 16px;
    background: $bg-secondary-blured;
    p {
      color: $text_icons-Secondary;
      text-align: center;
      font-size: 0.75rem;
      font-weight: 400;
    }
  }

}
@import "src/scss/variables";
@import "src/scss/mixin";

.conteiner {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 96px 32px;


  .wrapper {
    width: 100%;
    height: 480px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: #ffed24;
    border-radius: 24px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    box-shadow: 0 8px 8px rgba(16, 24, 40, .03), 0 20px 24px rgba(16, 24, 40, .08);

    .content {
      width: 100%;
      height: 480px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 64px;
      display: flex;

      .title {
        color: rgba(0, 0, 0, 0.90);
        font-size: 2.25rem;
        font-weight: 600;
        letter-spacing: -0.72px;
        margin-bottom: 20px;
      }

      .subtitle {
        color: rgba(0, 0, 0, 0.50);
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 40px;
      }

      .btns {
        display: flex;
        gap: 12px;

        .btn {
          background: none;
          border: none;
        }
      }
    }

    .imgs {
      width: 100%;
      height: 480px;
      padding-left: 37px;
      padding-right: 37px;
      display: block;
      position: relative;
      overflow: hidden;

      .badge__wrapper {
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        position: absolute;
        top: 151px;
        left: 0;

        img {
          object-fit: cover;
          border-radius: 12px;
        }
      }

      .iphone {
        width: 314px;
        object-fit: cover;
        position: absolute;
        top: 56px;
        left: auto;
        right: 48px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .conteiner {
    .wrapper {
      height: auto;
      align-self: stretch;
      align-items: stretch;
      overflow: hidden;
      .content {
        height: auto;
        padding: 32px;
      }
      .imgs {
        .badge__wrapper {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .conteiner {
    height: auto;
    padding: 64px 16px;
    .wrapper {
      flex-direction: column;
      overflow: hidden;
      .content {
        align-items: center;
        text-align: center;
        padding-bottom: 32px;

        .title {
          font-size: 1.875rem;
          letter-spacing: -0.6px;
        }

        .subtitle {
          font-size: 1.125rem;
          line-height: 30px;
        }
      }
      .imgs {
        height: 280px;
        display: flex;
        overflow: hidden;
        justify-content: center;
        .iphone {
          top: 0;
          right: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .conteiner {
    .wrapper {
      .content {
        .btns {
          grid-column-gap: 8px;
          flex: 0 1 auto;
          justify-content: space-between;
          align-items: flex-start;
          display: flex;
          width: 100%;
          flex-wrap: nowrap;
          .btn{
            object-fit: cover;
            justify-content: center;
            align-items: center;
            display: flex;
            &:first-of-type {
              width: 44%;
              flex: 0 1 auto;
              height: auto;

            }
            &:last-of-type {
              width: 50%;
              flex: 0 1 auto;
              height: auto;
            }
          }
        }
      }
      .imgs {
        .iphone {
          width: 268px;
        }
      }
    }
  }
}

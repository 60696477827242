@import "variables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: $bg-primary;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bg-secondary;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $bg-secondary;
  }
}

body {
  background: #121212;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  font-size: 1rem;
}

button {
  font-family: 'Inter', sans-serif;
  white-space: nowrap;
}

a {
  white-space: nowrap;
}

.App {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  overflow-x: hidden;

  .app_wrapper {
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.loader__wrapper {
  height: calc(100vh - 80px);
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid $text_icons-Primary;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  border-color: $brand-primary-strong;
  animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
  inset: 6px;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

/* inter-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/inter-v12-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/inter-v12-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/inter-v12-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
@import "src/scss/variables";
@import "src/scss/mixin";


.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 32px;
  width: 100%;

  .wrapper {
    max-width: 768px;
    text-align: center;
    margin-bottom: 64px;

    .badge {
      @include badge;
    }

    .title {
      @include h2title
    }

    .subtitle {
      @include subtitle
    }
  }

  .faq_container {
    width: 100%;
    max-width: 1280px;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    justify-content: center;
    align-items: flex-start;
    padding-left: 0;
    padding-right: 0;
    display: flex;
  }

  .more {
    display: none;
  }

  .faq__column {
    width: 100%;
    grid-column-gap: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .faq_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 32px;
    border-radius: 16px;
    border: 1px solid $text_icons-Borders;
    background: $bg-secondary;
    margin-top: 64px;

    .faq_bottom__title {
      color: $text_icons-Primary;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 8px;
    }

    .faq_bottom__subtitle {
      color: $text_icons-Secondary;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 28px;
    }

    .faq_bottom__btn {
      height: 48px;
      padding: 0 24px;
      border-radius: 8px;
      background: $brand-primary-strong;
      border: none;
      color: $text_icons-onPrimary;
      font-family: Inter, sans-serif;
      font-size: 1rem;
      font-weight: 600;
      line-height: 28px;
      display: flex;
      align-items: center;
    }
  }
}


@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
  .content {
    padding: 64px 16px;

    .faq_container {
      flex-direction: column;
      grid-column-gap: 16px;
      grid-row-gap: 0;
      position: relative;
    }

    .faq__column {
      width: 100%;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;

      &:nth-of-type(2) {
        height: 92px;
        overflow: hidden;

        &.full_column {
          height: fit-content;
          overflow: visible;
        }
      }
    }

    .open {
      display: none;
    }

    .more {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      background: linear-gradient(180deg, rgba(18, 18, 18, 0.00) 0%, #121212 76.56%);
      backdrop-filter: blur(2px);
      height: 92px;
      width: 100%;

      button {
        display: flex;
        align-items: center;
        color: $brand-primary-strong;
        font-family: Inter, sans-serif;
        font-size: 1rem;
        font-weight: 500;
        line-height: 28px;
        background: none;
        border: none;
        gap: 8px;

        svg path {
          stroke: $brand-primary-strong;
        }
      }
    }
    .faq_bottom {
      margin-top: 48px;
      gap: 16px;
    }
  }
}

@media screen and (max-width: 479px) {
  .content {
    .faq_bottom {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}